import React, { useState } from "react";
// import { useAuthState } from 'react-firebase-hooks/auth';
import MyRouter from "routers/index";
import swal from "sweetalert";
import { auth, getRedirectResult, getUserId } from 'config/firebase';
import { RecoilRoot } from 'recoil';

const Loader = () => {
  let circleCommonClasses = 'h-2.5 w-2.5 bg-current rounded-full';

  return (
    <div className="nc-Loader bg-white dark:bg-neutral-900 fixed z-50 top-0 left-0 right-0 flex w-full h-full items-center justify-center">
      <div className="flex justify-content-center text-primary-400 dark:text-primary-100">
        <div className={`${circleCommonClasses} text-inherit mr-1 animate-bounce`}></div>
        <div className={`${circleCommonClasses} text-inherit mr-1 animate-bounce200`}></div>
        <div className={`${circleCommonClasses} text-inherit animate-bounce400`}></div>
      </div>
    </div>
  );
};

function App() {
  // const [loadedUser, error] = useAuthState(auth);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  // Result from Redirect auth flow.
  getRedirectResult(auth).then(async (result) => {
    if (result) {
      // The signed-in user info.
      const user = result.user;
      if (user.email) {
        const userId = getUserId(user.email);
        // const q = await getDocs(query(usersRef, where('email', '==', user.email)));
        // if (q.docs.length === 0)
        // await setDoc(doc(db, 'users', userId), {
        //   uid: user.uid,
        //   name: user.displayName,
        //   authProvider: 'google',
        //   email: user.email,
        // });
        console.log(userId);
      } else {
        throw new Error('Missing user email!');
      }
    }
  }).catch((error) => {
    swal('Uh oh', 'There was an issue logging you in, please refresh!', 'error');
  });

  auth.onAuthStateChanged((user) => {
    setLoading(false);

    if (user) {
      setLoggedIn(true);
    }
  });

  return (
    <RecoilRoot>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        {(loading) ? (
          <Loader />
        ) : null}
        <MyRouter loggedIn={loggedIn} />
      </div>
    </RecoilRoot>
  );
}

export default App;
