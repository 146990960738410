import React, { FC, useState } from "react";
import Logo from "shared/Logo/Logo";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { validateEmail } from 'utils/validateEmail';
import { signinWithEmailAndPassword } from 'config/firebase';
import swal from "sweetalert";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const disabled = (email.length < 1) || (!validateEmail(email)) || (password.length < 1);

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!disabled) {
      signinWithEmailAndPassword(email, password).then((res) => {
        setEmail('');
        setPassword('');
      }).catch((err) => {
        if (err.code === 'auth/wrong-password') {
          swal('Error', 'Your password is incorrect!', 'error');
        } else if (err.code === 'auth/user-not-found') {
          swal('Error', 'Your account was not found!', 'error');
        } else {
          console.log(err.code);
        }
      });
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Serentio | Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <figure className="my-20 flex items-center justify-center"><Logo width="12rem" /></figure>
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="name@company.com"
                className="mt-1"
                onChange={handleChangeEmail}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" onChange={handleChangePassword} className="mt-1" />
            </label>
            <ButtonPrimary disabled={disabled} type="submit">Continue</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
