import Button, { ButtonProps } from "shared/Button/Button";
import React, { forwardRef } from "react";

export interface ButtonPrimaryProps extends ButtonProps {}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = forwardRef(({
  className = "",
  id = "primaryButton",
  ...args
}, ref) => {
  return (
    <Button
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      id={id}
      {...args}
    />
  );
});

export default ButtonPrimary;
