import React from 'react';
import Button from "shared/Button/Button";
// import ButtonPrimary from 'shared/Button/ButtonPrimary';
import CommonLayout from './CommonLayout';
import moment from 'moment';
import { useBookings, useListings } from 'config/firebase';

const AccountBookings = () => {
  const { bookings } = useBookings();
  const { listings } = useListings();
  const bookingsSorted = bookings.sort((a, b) => a.createdDate.seconds < b.createdDate.seconds ? 1 : -1);

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Bookings</h2>
          <div className="grid">
            <span className="text-neutral-700 dark:text-neutral-300 block text-sm">
              To make changes to your bookings, please contact us at <a className="font-semibold text-primary-700 dark:text-primary-500" href="mailto:support@serentio.com">support@serentio.com</a>.<br /><br />
              If you're looking to <a className="font-semibold text-primary-700 dark:text-primary-500" href="/reimbursements">get reimbursed</a> for a booking you made yourself, please submit your receipt to <a className="font-semibold text-primary-700 dark:text-primary-500" href="mailto:reimbursements@serentio.com">reimbursements@serentio.com</a>.
            </span>
            <div className="w-full border-b border-neutral-200 my-4 dark:border-neutral-700"></div>
            <span className="text-xl font-semibold block">History</span>
            <br />
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full">
                  <div className="grid grid-cols-1">
                    <table className="min-w-full">
                      <thead className="border-b dark:border-neutral-700">
                        <tr>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Request Submitted
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Status
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Listing
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Booking Dates
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Weeks
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Credits Spent
                          </th>
                        </tr>
                      </thead>
                      {
                        bookingsSorted.length > 0 ? (
                          <tbody>
                            {
                              bookingsSorted.slice(0, 50).map((booking, index) => {
                                let listing = listings.find(listing => listing.id === booking.listingId);
                                if (booking.listingId === 'REIMBURSEMENT') {
                                  listing = { name: 'Reimbursement' };
                                }
                                if (!listing) {
                                  listing = { name: 'Unknown' };
                                }

                                const bookingCreatedDate = new Date((booking.createdDate.seconds * 1000) - (1000 * 60 * 12));
                                const bookingStartDate = new Date((booking.bookingStartDate.seconds * 1000) - (1000 * 60 * 12));
                                const bookingEndDate = new Date((booking.bookingEndDate.seconds * 1000) - (1000 * 60 * 12));
                                // const bookingWeeks = moment.duration(bookingEndDate.diff(bookingStartDate)).asWeeks();

                                const displayCreatedDate = bookingCreatedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
                                const displayStartDate = bookingStartDate.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
                                const displayEndDate = bookingEndDate.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
                                
                                return (
                                  <tr className="border-b dark:border-neutral-700" key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">{displayCreatedDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">{booking.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                                      {
                                        listing && listing.id ? (
                                          <Button className="hover:text-neutral-500 focus:ring-0 focus:ring-offset-0 simpleButton" href={`/listings/${listing.id}`}>{listing.name}</Button>
                                        ) : listing.name === 'Reimbursement' ? (
                                          <span className="text-neutral-500 dark:text-neutral-400">
                                            Reimbursement
                                            {(booking.comments && booking.comments.length > 0) && (
                                              <span> - {booking.comments}</span>
                                            )}
                                          </span>
                                        ) : (
                                          <span className="text-neutral-500 dark:text-neutral-400">Uknown Listing</span>
                                        )
                                      }
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                                      <span style={{ color: '#555', fontWeight: 'normal' }}>From</span> <strong>{displayStartDate}</strong><br />
                                      <span style={{ color: '#555', fontWeight: 'normal' }}>to</span> <strong>{displayEndDate}</strong>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100 text-center">
                                      {booking.weeks}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100 text-center">
                                      {booking.creditsSpent}
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        ) : null
                      }
                    </table>
                    <div className="my-4 text-neutral-700 dark:text-neutral-300 block text-sm">
                      For more bookings history and reports, please contact us at <a className="font-semibold text-primary-700 dark:text-primary-500" href="mailto:support@serentio.com">support@serentio.com</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBookings;
