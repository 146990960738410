import React, { FC, useState, Fragment } from "react";
import Button from "shared/Button/Button";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Popover, Transition } from "@headlessui/react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { LogoutIcon, CogIcon, ViewListIcon, CalendarIcon } from "@heroicons/react/outline";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { auth, logout, useParticipant } from 'config/firebase';

export interface MainNav1Props {
  isTop: boolean;
}

// NOTE: this is used
const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const [user, error] = useAuthState(auth);
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);
  const { participant } = useParticipant();

  const handleLogout = () => {
    logout();
    window.location.href = '/';
  };

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <SwitchDarkMode />
            {/* <SearchDropdown /> */}
            <div className="space-x-4">
              <Button className="hover:text-neutral-500 focus:ring-0 focus:ring-offset-0" href="/account-billing">{participant.activeCredits} Credits Available</Button>
            </div>
            <Popover
              className="menu-dropdown relative"
              onMouseEnter={() => onMouseEnterMenu('account-menu')}
              onMouseLeave={() => onMouseLeaveMenu('account-menu')}
            >
              <Popover.Button as={Fragment}>
                <ButtonPrimary className="w-60" id="primaryButtonMenu" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="text-ellipsis overflow-hidden">{user ? user.email : 'Account'}</span>
                  <span className="h-4 w-4">
                    <ChevronDownIcon
                      className="ml-1 -mr-1 h-4 w-4 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </ButtonPrimary>
              </Popover.Button>
              <Transition
                as={Fragment}
                show={ menuCurrentHovers.includes('account-menu')}
                enter="transition ease-out duration-150"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="sub-menu nc-will-change-transform absolute transform z-10 w-60 pt-3"
                >
                  <ul className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 text-sm relative bg-white text-neutral-900 p-2 grid space-y-1">
                    <li className="w-full">
                      <Button className="px-2 hover:bg-neutral-100 focus:ring-0 focus:ring-offset-0 rounded-lg w-full" href="/account">
                        <CogIcon className="h-4 w-4 mr-2 text-inherit"/>
                        Account
                      </Button>
                    </li>
                    <li className="w-full">
                      <Button className="px-2 hover:bg-neutral-100 focus:ring-0 focus:ring-offset-0 rounded-lg w-full" href="/account-billing">
                        <ViewListIcon className="h-4 w-4 mr-2 text-inherit"/>
                        Contributions
                      </Button>
                    </li>
                    <li className="w-full">
                      <Button className="px-2 hover:bg-neutral-100 focus:ring-0 focus:ring-offset-0 rounded-lg w-full" href="/account-bookings">
                        <CalendarIcon className="h-4 w-4 mr-2 text-inherit"/>
                        Bookings
                      </Button>
                    </li>
                    <li className="w-full">
                      <Button className="px-2 hover:bg-neutral-100 focus:ring-0 focus:ring-offset-0 rounded-lg w-full" onClick={handleLogout}>
                        <LogoutIcon className="h-4 w-4 mr-2 text-inherit"/>
                        Logout
                      </Button>
                    </li>
                  </ul>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
          <div className="flex items-center xl:hidden">
            <SwitchDarkMode />
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
