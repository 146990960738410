import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import md5 from 'md5';
import swal from 'sweetalert';
import useSWR from 'swr';
import {
  getFirestore,
  // doc,
  // setDoc,
  // getDoc,
  getDocs,
  collection,
  // updateDoc,
  // collectionGroup,
  query,
  where,
  limit,
  // connectFirestoreEmulator,
} from 'firebase/firestore';
import {
  getAuth,
  getRedirectResult,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAndsQkXnroKbjFoi3JttT8sTYK4TG8Jlw',
  authDomain: 'serentio.firebaseapp.com',
  projectId: 'serentio',
  storageBucket: 'serentio.appspot.com',
  messagingSenderId: '655678652626',
  appId: '1:655678652626:web:63348a327012883eb52d0b',
  measurementId: 'G-8KKQDFJJKQ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore();
// const companiesRef = collection(db, 'companies');
const participantsRef = collection(db, 'participants');
const listingsRef = collection(db, 'listings');
const bookingsRef = collection(db, 'bookings');
const contributionsRef = collection(db, 'contributions');

// functions
const createBooking = httpsCallable(functions, 'createBooking');

function useListings() {
  const { data, error } = useSWR(`/api/listings/`, getListings);

  var listings: any[] = data as any;
  if (listings && (listings.length > 0)) {
    listings = listings.map((listing: any) => {
      const photos = listing.photos.map((p: string) => `https://firebasestorage.googleapis.com/v0/b/serentio.appspot.com/o/images%2F${p}?alt=media`) || [];
      
      let price = `${listing.credits} Credit`;
      if (listing.credits > 1) {
        price = `${listing.credits} Credits`;
      }
      
      return {
        ...listing,
        author: { name: '' },
        date: new Date(),
        href: '/listings/' + listing.id,
        listingCategory: { name: listing.type },
        title: listing.name,
        featuredImage: photos[0],
        galleryImgs: photos,
        commentCount: 70,
        viewCount: 602,
        like: false,
        address: listing.address,
        reviewStart: 4.8,
        reviewCount: 28,
        price,
        maxGuests: listing.guests,
        bedrooms: listing.bedrooms,
        bathrooms: listing.bathrooms,
        saleOff: '',
        isAds: null,
        map: { 'lat': listing.geocode.latitude, "lng": listing.geocode.longitude }
      };
    });
  }

  return {
    listings: listings || [],
    isLoading: !error && !data,
    isError: error
  };
}

const getListings = async () => {
  const querySnapshot = await getDocs(listingsRef);
  const listings: any[] = [];

  querySnapshot.forEach((doc) => {
    listings.push(doc.data());
  });

  return listings;
}

function useContributions() {
  const { data, error } = useSWR(`/api/contributions/`, getContributions);

  return {
    contributions: data || [],
    isLoading: !error && !data,
    isError: error
  };
}

const getContributions = async () => {
  const queryStatement = query(contributionsRef, where('email', '==', auth.currentUser?.email));
  const querySnapshot = await getDocs(queryStatement);
  const contributions: any[] = [];

  querySnapshot.forEach((doc) => {
    contributions.push(doc.data());
  });

  return contributions;
}

function useBookings() {
  const { data, error } = useSWR(`/api/bookings/`, getBookings);

  return {
    bookings: data || [],
    isLoading: !error && !data,
    isError: error
  };
}

const getBookings = async () => {
  const queryStatement = query(bookingsRef, where('email', '==', auth.currentUser?.email), limit(50));
  const querySnapshot = await getDocs(queryStatement);
  const bookings: any[] = [];

  querySnapshot.forEach((doc) => {
    bookings.push(doc.data());
  });

  return bookings;
}

function useParticipant() {
  const { data, error } = useSWR(`/api/participant/`, getParticipant);

  const nullParticipant = {
    activeCredits: 0,
    companyId: '',
    companyLogo: null,
    companyName: '',
    creditPrice: 0,
    email: '',
    enrolledDate: { seconds: 0},
    vestedCredits: 0,
    id: '',
    lastModifiedDate: { seconds: 0},
    name: '',
    role: 'Member',
    spentCredits: 0,
    terminatedDate: null,
    employerPaymentPerPayrollPerEmployee: 0,
    employeePaymentPerPayroll: 0,
  };

  return {
    participant: data || {...nullParticipant},
    isLoading: !error && !data,
    isError: error
  };
}

const getParticipant = async () => {
  const queryStatement = query(participantsRef, where('email', '==', auth.currentUser?.email));
  const querySnapshot = await getDocs(queryStatement);
  const participants: any[] = [];

  querySnapshot.forEach((doc) => {
    participants.push(doc.data());
  });

  if (participants.length > 0) {
    return participants[0];
  }

  return null;
}

const signinWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }
};

const getUserId = (email: string) => {
  const userId = md5(email).toUpperCase();
  return userId;
}

const getProfile = async (user: any) => {
  try {
    if (user && user.email) {
      const userId = getUserId(user.email);
      // const profileDoc = await getDoc(doc(db, 'profiles', userId));
      // const profileData = (profileDoc.data() || emptyProfileData) as IProfileData;
      return {
        ...user,
        userId,
      };
    } else {
      throw new Error('Missing user id');
    }
  } catch (err) {
    throw err;
  }
}

// const updateProfile = async (user: any, name: string, profileData: IProfileData) => {
const updateProfile = async (user: any) => {
  try {
    if (user && user.email) {
      const userId = getUserId(user.email);
      // await setDoc(doc(db, 'profiles', userId), profileData);
      // await updateDoc(doc(db, 'users', userId), { name });
      console.log(userId);
    } else {
      throw new Error('Missing user id');
    }
  } catch (err) {
    throw err;
  }
};

// change a password
const updateUserPassword = async (email: string, oldPassword: string, newPassword: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, oldPassword);
    const user = getAuth().currentUser;

    if (user) {
      return updatePassword(user, newPassword);
    } else {
      throw new Error('Could not validate the user!');
    }
  } catch (err) {
    throw err;
  }
};

const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    swal('Success', 'Your password has been reset and a link sent to your email.', 'success');
  } catch (err) {
    swal('Uh oh', 'We ran into an issue resetting your password. Please try again.', 'error');
    throw err;
  }
};

const logout = () => {
  auth.signOut();
};

export {
  app,
  analytics,
  auth,
  db,
  createBooking,
  getListings,
  useListings,
  getContributions,
  useContributions,
  getBookings,
  useBookings,
  getParticipant,
  useParticipant,
  getUserId,
  getRedirectResult,
  updateUserPassword,
  signinWithEmailAndPassword,
  resetPassword,
  logout,
  getProfile,
  updateProfile,
};
