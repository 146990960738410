import React, { FC, Fragment, useState } from 'react';
import { useSWRConfig } from 'swr';
import { Dialog, Transition } from '@headlessui/react';
// import { ArrowRightIcon } from '@heroicons/react/outline';
import LocationMarker from 'components/AnyReactComponent/LocationMarker';
// import CommentListing from 'components/CommentListing/CommentListing';
// import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import Textarea from 'shared/Textarea/Textarea';
import GuestsInput from 'components/HeroSearchForm/GuestsInput';
import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
import Label from 'components/Label/Label';
// import { DateRage } from 'components/HeroSearchForm/StaySearchForm';
// import StartRating from 'components/StartRating/StartRating';
import GoogleMapReact from 'google-map-react';
import useWindowSize from 'hooks/useWindowResize';
import moment from 'moment';
// import { DayPickerRangeController FocusedInputShape } from 'react-dates';
// import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
// import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
// import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
// import LikeSaveBtns from './LikeSaveBtns';
import ModalPhotos from './ModalPhotos';
// import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
// import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
// import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { createBooking, getParticipant, useListings, useParticipant } from 'config/firebase';
import { useRecoilState } from 'recoil';
import { bookingDateRangeState, bookingGuestState, bookingLocationState } from 'atoms/bookingState';

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

function humanize(str: string) {
  var i, frags = str.toLowerCase().split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

// const PHOTOS: string[] = [
//   "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
// ];

const amenities_icons: any = {
  INTERNET_ACCESS: 'la-wifi',
  LINEN_PROVIDED: 'la-bed',
  TOWELS_PROVIDED: 'la-shower',
  CENTRAL_HEATING: 'la-temperature-high',
  AIR_CONDITIONING: 'la-temperature-low',
  MICROWAVE: 'la-broadcast-tower',
  FRIDGE: 'la-thermometer-quarter',
  DISHES: 'la-utensil-spoon',
  COFFEEMAKER: 'la-coffee',
  FULL_KITCHEN: 'la-utensils',
  DISH_WASHER: 'la-tint',
  PANTRY_ITEMS: 'la-boxes',
  TOASTER: 'la-bread-slice',
  OVEN: 'la-birthday-cake',
  GRILL: 'la-hamburger',
  SPICES: 'la-mortar-pestle',
  ICE_MAKER: 'la-snowflake',
  TV: 'la-tv',
  SATELLITE_OR_CABLE: 'la-satellite-dish',
  BOOKS: 'la-book',
  WASHING_MACHINE: 'la-tint',
  CLOTHES_DRYER: 'la-tshirt',
  PARKING: 'la-car',
  SMOKE_DETECTOR: 'la-fire',
  FIRE_EXTINGUISHER: 'la-fire-extinguisher',
  CARBON_MONOXIDE_DETECTOR: 'la-luggage-cart',
  FIRST_AID_KIT: 'la-medkit',
  WATERFRONT: 'la-water',
  LAKE: 'la-swimmer',
  KAYAKING: 'la-swimming-pool',
  MINIMUM_AGE_LIMIT_FOR_RENTERS: 'la-child',
  BEACH: 'la-umbrella-beach',
  HAIR_DRYER: 'la-fan',
  WIRELESS_BROADBAND: 'la-broadcast-tower',
  TOILET_PAPER: 'la-toilet-paper',
  PAPER_TOWELS: 'la-hand-paper',
  BLENDER: 'la-cocktail',
  KETTLE: 'la-mug-hot',
  DINING_TABLE: 'la-table',
  KITCHEN_ISLAND: 'la-archway',
  OUTDOOR_FURNITURE: 'la-couch',
  BEACH_CHAIRS: 'la-chair',
  BEACH_TOWELS: 'la-solar-panel',
  SMART_TV: 'la-youtube-square',
  OUTDOOR_LIGHTING: 'la-lightbulb',
  DEADBOLT_LOCK: 'la-lock',
  WHEELCHAIR_INACCESSIBLE: 'la-slash',
  BIKE: 'la-bicycle',
  NEAR_THE_SEA: 'la-location-arrow',
  CYCLING: 'la-biking',
  BIRD_WATCHING: 'la-crow',
  SHOPPING: 'la-tags',
  BOATING: 'la-anchor',
  MUSEUMS: 'la-landmark',
  WILDLIFE_VIEWING: 'la-tree',
  MARINA: 'la-ship',
  PADDLE_BOATING: 'la-table-tennis',
  OUTLET_SHOPPING: 'la-store-alt',
  ECO_TOURISM: 'la-leaf',
  WATER_SPORTS: 'la-swimmer',
  SOAPS_BASIC: 'la-bath',
  SHAMPOO: 'la-shower',
  IRON_AND_BOARD: 'la-tshirt',
  GARAGE: 'la-warehouse',
  GAMES_ADULTS: 'la-dice',
  RIVER: 'la-water',
  GAME_ROOM: 'la-trophy',
  FIREPLACE: 'la-fire-alt',
  TELEPHONE: 'la-phone-volume',
  FITNESS_ROOM: 'la-skiing-nordic',
  COMMUNAL_POOL: 'la-swimming-pool',
  HEATED_POOL: 'la-thermometer-half',
  SAUNA: 'la-spa',
  DVD: 'la-compact-disc',
  ELEVATOR: 'la-caret-square-up',
  MOUNTAIN_VIEW: 'la-image',
  SKI_IN_OR_SKI_OUT: 'la-igloo',
  SWIMMING: 'la-swimmer',
  FISHING: 'la-fish',
  GOLF_SPORTS_AND_ADVENTURE_ACTIVITIES: 'la-golf-ball',
  HIKING: 'la-hiking',
  HORSE_RIDING: 'la-horse-head',
  MOUNTAIN_BIKING: 'la-bicycle',
  HEALTH_OR_BEAUTY_SPA: 'la-hot-tub',
  ANTIQUING: 'la-holly-berry',
  SKI: 'la-snowman',
  RAFTING: 'la-dot-circle',
  HUNTING: 'la-tree',
  SLEDDING: 'la-sleigh',
  ROCK_CLIMBING: 'la-mountain',
  SNOWBOARDING: 'la-snowboarding',
  CROSS_COUNTRY_SKIING: 'la-skiing',
  SPELUNKING: 'la-hiking',
}
// { name: "la-key", icon: "la-key" },
// { name: "la-luggage-cart", icon: "la-luggage-cart" },
// { name: "la-shower", icon: "la-shower" },
// { name: "la-smoking", icon: "la-smoking" },
// { name: "la-snowflake", icon: "la-snowflake" },
// { name: "la-spa", icon: "la-spa" },
// { name: "la-suitcase", icon: "la-suitcase" },
// { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
// { name: "la-swimmer", icon: "la-swimmer" },
// { name: "la-swimming-pool", icon: "la-swimming-pool" },
// { name: "la-tv", icon: "la-tv" },
// { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
// { name: "la-utensils", icon: "la-utensils" },
// { name: "la-wheelchair", icon: "la-wheelchair" },
// { name: "la-wifi", icon: "la-wifi" },
// { name: "la-baby-carriage", icon: "la-baby-carriage" },
// { name: "la-bath", icon: "la-bath" },
// { name: "la-bed", icon: "la-bed" },
// { name: "la-briefcase", icon: "la-briefcase" },
// { name: "la-car", icon: "la-car" },
// { name: "la-cocktail", icon: "la-cocktail" },
// { name: "la-coffee", icon: "la-coffee" },
// { name: "la-concierge-bell", icon: "la-concierge-bell" },
// { name: "la-dice", icon: "la-dice" },
// { name: "la-dumbbell", icon: "la-dumbbell" },
// { name: "la-hot-tub", icon: "la-hot-tub" },
// { name: "la-infinity", icon: "la-infinity" },

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const { mutate } = useSWRConfig();
  const { participant } = useParticipant();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [locationInputValue, setLocationInputValue] = useRecoilState(bookingLocationState) as any;
  const [selectedDate, setSelectedDate] = useRecoilState(bookingDateRangeState) as any;
  const [guestValue, setGuestValue] = useRecoilState(bookingGuestState) as any;
  const [specialRequest, setSpecialRequest] = useState('');
  const [bookingLoading, setBookingLoading] = useState(false);

  // check location input value
  if (locationInputValue === null) {
    console.log(locationInputValue);
  }

  // set booking weeks
  let bookingWeeks = 0;
  if (selectedDate.startDate && selectedDate.endDate) {
    bookingWeeks = moment.duration(selectedDate.endDate.diff(selectedDate.startDate)).asWeeks();
  }

  // get other values from the listing
  const { listings } = useListings();
  const hrefParts = window.location.href.split('/');
  const listingId = hrefParts[hrefParts.length - 1];
  const listing = listings.find(listing => listing.id === listingId) || {
    id: '',
    title: '',
    galleryImgs: [],
    geocode: {
      longitude: -84.386330,
      latitude: 33.753746,
    },
    cancellation: [],
    specialNotes: [],
    description: '',
    checkin: '',
    checkout: '',
    amenities: [],
    minWeeks: 1,
    maxWeeks: 4,
    credits: 1,
  };
  const PHOTOS = listing.galleryImgs;
  const displayCheckin = 'After ' + listing.checkin;
  const displayCheckout = 'Before ' + listing.checkout;
  const amenities = listing.amenities.map((amenity: string) => {
    let key = amenity;
    if (key === 'HOB') {
      key = 'PANTRY_ITEMS';
    }
    let icon = 'la-key';
    if (amenities_icons[key]) {
      icon = amenities_icons[key];
    } else {
      console.log(key);
    }

    return { 
      name: humanize(key),
      icon,
    };
  });

  const creditsPrice = listing.credits;
  let price = `${creditsPrice} Credit`;
  if (creditsPrice > 1) {
    price = `${creditsPrice} Credits`;
  }

  const creditsAvailable = participant.activeCredits;
  const creditsToSpend = creditsPrice * bookingWeeks;
  const creditsRemaining = creditsAvailable - creditsToSpend;

  let bookingValid = true;
  if (bookingWeeks > listing.maxWeeks) {
    bookingValid = false;
  }
  if (bookingWeeks < listing.minWeeks) {
    bookingValid = false;
  }
  if (creditsRemaining < 0) {
    bookingValid = false;
  }
  const totalGuests = guestValue.guestAdults + guestValue.guestChildren + guestValue.guestInfants;
  if (totalGuests > listing.guests) {
    bookingValid = false;
  }
  if (totalGuests < 1) {
    bookingValid = false;
  }

  // const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] = useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  // const getDaySize = () => {
  //   if (windowSize.width <= 375) {
  //     return 34;
  //   }
  //   if (windowSize.width <= 500) {
  //     return undefined;
  //   }
  //   if (windowSize.width <= 1280) {
  //     return 56;
  //   }
  //   return 48;
  // };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  function handleMobileBooking () {
    (document.getElementById('bookingsection') as any).scrollIntoView({ behavior: 'smooth' });
    if (bookingValid) {
      handleBooking();
    }
  }

  function handleBooking () {
    setBookingLoading(true);

    const data = {
      listingId: listing.id,
      guests: guestValue,
      weeks: bookingWeeks,
      status: 'PENDING',
      creditsSpent: creditsToSpend,
      createdDate: new Date(),
      comments: specialRequest,
      bookingStartDate: selectedDate.startDate.toDate(),
      bookingEndDate: selectedDate.endDate.toDate(),
    };

    // create the booking
    createBooking(data).then((bookingResult) => {
      console.log('Successful booking');
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setSpecialRequest('');
      setGuestValue({ guestAdults: 0, guestChildren: 0, guestInfants: 0 });
      setSelectedDate({ startDate: null, endDate: null });
      setLocationInputValue('');
      setBookingLoading(false);
      mutate('/api/participant/');
    });
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={listing.type} />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {listing.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating /> */}
          {/* <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{listing.city}</span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {listing.guests} <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {listing.beds} <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {listing.bathrooms} <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {listing.bedrooms} <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {listing.description.split('\n').map((item: string, key: number) => <p key={key} style={{ marginBottom: 20}}>{item}</p>)}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {amenities.filter((_: any, i: number) => i < 12).map((item: any) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more {amenities.length} amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {amenities.filter((_: any, i: number) => i < 1212).map((item: any) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Credits</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Credits required may increase during busy periods or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {/* <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>January - April</span>
              <span>{listing.credits} Credit</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>May - August</span>
              <span>{listing.credits * 2} Credits</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>September - December</span>
              <span>{listing.credits} Credit</span>
            </div> */}
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of weeks</span>
              <span>{listing.minWeeks} week</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Maximum number of weeks</span>
              <span>{listing.maxWeeks} weeks</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderSectionCheckIndate = () => {
  //   return (
  //     <div className="listingSection__wrap overflow-hidden">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Availability</h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}

  //       <div className="listingSection__wrap__DayPickerRangeController flow-root">
  //         <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
  //           <DayPickerRangeController
  //             startDate={selectedDate.startDate}
  //             endDate={selectedDate.endDate}
  //             onDatesChange={(date) => setSelectedDate(date)}
  //             focusedInput={focusedInputSectionCheckDate}
  //             onFocusChange={(focusedInput) =>
  //               setFocusedInputSectionCheckDate(focusedInput || "startDate")
  //             }
  //             initialVisibleMonth={null}
  //             numberOfMonths={windowSize.width < 1280 ? 1 : 2}
  //             daySize={getDaySize()}
  //             hideKeyboardShortcutsPanel={false}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection5 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Host Information</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* host */}
  //       <div className="flex items-center space-x-4">
  //         <Avatar
  //           hasChecked
  //           hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
  //           sizeClass="h-14 w-14"
  //           radius="rounded-full"
  //         />
  //         <div>
  //           <a className="block text-xl font-medium" href="##">
  //             Kevin Francis
  //           </a>
  //           <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
  //             <StartRating />
  //             <span className="mx-2">·</span>
  //             <span> 12 places</span>
  //           </div>
  //         </div>
  //       </div>

  //       {/* desc */}
  //       <span className="block text-neutral-6000 dark:text-neutral-300">
  //         Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
  //         accommodation, an outdoor swimming pool, a bar, a shared lounge, a
  //         garden and barbecue facilities...
  //       </span>

  //       {/* info */}
  //       <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //             />
  //           </svg>
  //           <span>Joined in March 2016</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
  //             />
  //           </svg>
  //           <span>Response rate - 100%</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
  //             />
  //           </svg>

  //           <span>Fast response - within a few hours</span>
  //         </div>
  //       </div>

  //       {/* == */}
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       <div>
  //         <ButtonSecondary href="##">See host profile</ButtonSecondary>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection6 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* Content */}
  //       <div className="space-y-5">
  //         <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
  //         <div className="relative">
  //           <Input
  //             fontClass=""
  //             sizeClass="h-16 px-4 py-3"
  //             rounded="rounded-3xl"
  //             placeholder="Share your thoughts ..."
  //           />
  //           <ButtonCircle
  //             className="absolute right-2 top-1/2 transform -translate-y-1/2"
  //             size=" w-12 h-12 "
  //           >
  //             <ArrowRightIcon className="w-5 h-5" />
  //           </ButtonCircle>
  //         </div>
  //       </div>

  //       {/* comment */}
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <div className="pt-8">
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {listing.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDRBrdouuTGgXoa73qKSXT27nk73VTA3nQ",
              }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              center={{
                lat: listing.geocode.latitude,
                lng: listing.geocode.longitude,
              }}
            >
              <LocationMarker lat={listing.geocode.latitude} lng={listing.geocode.longitude} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {listing.cancellation.map((item: any, i: number) => <p key={i} dangerouslySetInnerHTML={{ __html: item}} />)}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>{displayCheckin}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>{displayCheckout}</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              {
                listing.specialNotes.map((item: string, i:number) => <li key={i} dangerouslySetInnerHTML={{ __html: item}} />)
              }
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl px-6 py-6">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {price}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /week
            </span>
          </span>
          {/* <StartRating /> */}
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={guestValue as any}
            onChange={(data: any) => setGuestValue(data)}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Available Credits</span>
            <span>{creditsAvailable} Credits</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{price} x {bookingWeeks} { bookingWeeks === 1 ? 'week' : 'weeks' }</span>
            <span>{creditsToSpend} Credits</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{creditsToSpend} Credits Used</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Credits Remaining</span>
            <span>{creditsRemaining} Credits</span>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <Label>Special Requests</Label>
          <Textarea className="mt-1.5" value={specialRequest} onChange={(e) => setSpecialRequest(e.target.value)} />
        </div>

        {/* SUBMIT */}
        <ButtonPrimary disabled={!bookingValid} onClick={handleBooking} loading={bookingLoading}>Submit Request</ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_: any, i: number) => i >= 1 && i < 5).map((item: any, index: number) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSection4()}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {renderSection7()}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0" id="bookingsection">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              {price}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /week
              </span>
            </span>

            <ButtonPrimary onClick={handleMobileBooking} loading={bookingLoading}>Submit Request</ButtonPrimary>
          </div>
        </div>
      )}

      {/* OTHER SECTION */}
      
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingStayDetailPage1"}
            />
          </div> */}

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default ListingStayDetailPage;
