import StartRating from "components/StartRating/StartRating";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";

export interface PageReimbursementsProps {
  className?: string;
}

const PageReimbursements: FC<PageReimbursementsProps> = ({ className = "" }) => {
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Not finding what you're looking for?
        </h2>
        <p className="text-neutral-500 dark:text-neutral-400">
          We're here to help. If you can't find what you're looking for, book it yourself and send us a
          receipt to get reimbursed for your stay.
        </p>
        <div>
        <h3 className="text-2xl font-semibold mb-4">Here's how to get reimbursed:</h3>
          <ul className="list-decimal px-4 space-y-2 text-neutral-600 dark:text-neutral-300">
            <li>Book the listing yourself on whatever platform you choose.</li>
            <li>Email us the receipt at <a href="mailto:reimbursements@serentio.com" className="font-semibold text-primary-700 dark:text-primary-500">reimbursements@serentio.com</a>.<br /><br /><strong>Be sure to include:</strong>
            <ul className="list-disc py-4 px-4 space-y-2 text-neutral-600 dark:text-neutral-300"><li>Your Serentio login email</li><li>How many credits you want to be reimbursed</li><li>A clearly visible receipt either forwarded in the email or attached</li><li>How you want the money transferred to you</li></ul></li>
            <li>We'll review your submission, and if approved, we'll transfer your reimbursement!</li>
          </ul>
          <p className="my-4 text-neutral-600 dark:text-neutral-300 text-sm"><strong>Note:</strong> a 5% fee is applied to all credit reimbursements.</p>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PageReimbursements ${className}`} data-nc-id="PageReimbursements">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PageReimbursements;
