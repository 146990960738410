import React, { FC, ReactNode } from 'react';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import Heading from 'shared/Heading/Heading';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import HeaderFilter from './HeaderFilter';
import StayCard from 'components/StayCard/StayCard';
import { useListings } from 'config/firebase';

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 4);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = '',
  heading = 'Featured listings',
  subHeading = 'Here are some great locations we recommend',
  headingIsCenter,
  tabs = ['New York', 'Tokyo', 'Paris', 'London'],
}) => {
  const { listings } = useListings();
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };

  return (
    <div className='nc-SectionGridFeaturePlaces relative'>
      <Heading desc={subHeading}>{heading}</Heading>
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {(listings as any).map((stay: any) => renderCard(stay))}
      </div>
      {/* <div className='flex mt-16 justify-center items-center'>
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
      <div className="flex mt-16 justify-center items-center flex-col">
        <Heading
          className="text-neutral-900 dark:text-neutral-50"
          desc="Book it yourself and send us a receipt to get reimbursed for your stay."
          isCenter
        >
          Don't see what you want?
        </Heading>
        <div className="">
          <ButtonPrimary href="/reimbursements">Get reimbursed</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
