import { useParticipant } from "config/firebase";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Input from 'shared/Input/Input';
import moment from 'moment';
import FormItem from "containers/PageAddListing1/FormItem";
import Select from "shared/Select/Select";
import Label from 'components/Label/Label';
import { useRecoilState } from "recoil";
import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
import GuestsInput from 'components/HeroSearchForm/GuestsInput';
import { bookingDateRangeState, bookingGuestState, bookingLocationState } from "atoms/bookingState";
import useWindowSize from "hooks/useWindowResize";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Textarea from "shared/Textarea/Textarea";

const BookingWizardPage: React.FC = () => {
  const { participant } = useParticipant();
  const { name } = participant;
  const nameParts = name.split(' ')
  const [locationInputValue, setLocationInputValue] = useRecoilState(bookingLocationState) as any;
  const [selectedDate, setSelectedDate] = useRecoilState(bookingDateRangeState) as any;
  const [guestValue, setGuestValue] = useRecoilState(bookingGuestState) as any;
  const windowSize = useWindowSize();
  const [specialRequest, setSpecialRequest] = useState('');
  const [bookingLoading, setBookingLoading] = useState(false);

  // figure out the first name
  let firstName = ''
  if (nameParts.length > 1) {
    firstName = nameParts[0];
  } else {
    firstName = name;
  }

  // set booking weeks
  let bookingWeeks = 0;
  if (selectedDate.startDate && selectedDate.endDate) {
    bookingWeeks = moment.duration(selectedDate.endDate.diff(selectedDate.startDate)).asWeeks();
  }

  // temp
  const creditsPrice = 1;
  let price = `${creditsPrice} Credit`;
  if (creditsPrice > 1) {
    price = `${creditsPrice} Credits`;
  }

  // figure out booking
  const creditsAvailable = participant.activeCredits;
  const creditsToSpend = creditsPrice * bookingWeeks;
  const creditsRemaining = creditsAvailable - creditsToSpend;

  let bookingValid = true;
  // if (bookingWeeks > listing.maxWeeks) {
  //   bookingValid = false;
  // }
  // if (bookingWeeks < listing.minWeeks) {
  //   bookingValid = false;
  // }
  if (creditsRemaining < 0) {
    bookingValid = false;
  }
  const totalGuests = guestValue.guestAdults + guestValue.guestChildren + guestValue.guestInfants;
  // if (totalGuests > listing.guests) {
  //   bookingValid = false;
  // }
  if (totalGuests < 1) {
    bookingValid = false;
  }

  // handle the booking
  const handleBooking = () => {
    console.log('DOING THE BOOKING YO!!!');
  }

  return (
    <div className="nc-BookingWizardPage">
      <Helmet>
        <title>Serentio || Book your experience</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          {firstName}'s Experience
        </header>
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-2 my-6 p-5">
            <div className="max-w-2xl space-y-6">
              <h3 className="space-y-6">Where are you going?</h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <Select>
                    <option value="must">Must be</option>
                    <option value="should">Should be</option>
                    <option value="could">Could be</option>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Input type="text" value={locationInputValue} readOnly />
                </div>
              </div>
            </div>
            <div className="max-w-2xl space-y-6 my-6">
              <h3 className="space-y-6">When are you going?</h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <Select value="should">
                    <option value="must">Must be</option>
                    <option value="should">Should be</option>
                    <option value="could">Could be</option>
                  </Select>
                </div>
                <div className="space-y-2">
                  <StayDatesRangeInput
                    wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
                    onChange={(date) => setSelectedDate(date)}
                    numberOfMonths={1}
                    fieldClassName="p-5"
                    defaultValue={selectedDate}
                    anchorDirection={windowSize.width > 1400 ? "left" : "right"}
                  />
                </div>
              </div>
            </div>
            <div className="max-w-2xl space-y-6 my-6">
              <h3 className="space-y-6">How many credits per week?</h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <Select value="should">
                    <option value="must">Must be</option>
                    <option value="should">Should be</option>
                    <option value="could">Could be</option>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Select>
                    <option value="1">1 credit per week (Standard)</option>
                    <option value="2">2 credits per week (Luxury)</option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="max-w-2xl space-y-6 my-6">
              <h3 className="space-y-6">What are you looking for in the experience?</h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <Select value="could">
                    <option value="must">Must be</option>
                    <option value="should">Should be</option>
                    <option value="could">Could be</option>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Input type="text" />
                </div>
              </div>
            </div>
            <div className="max-w-2xl space-y-6 my-6">
              <h3 className="space-y-6">Who is going with you?</h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <GuestsInput
                    fieldClassName="p-5"
                    defaultValue={guestValue as any}
                    onChange={(data: any) => setGuestValue(data)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2 my-6 p-5">
            <div>
              <h3>Estimate of number of results:</h3>
              <p className="py-6">Few options --- Many options (use react d3 speedomer)</p>
            </div>
            <div className="py-6">
              <h3>Booking Summary</h3>
              {/* SUM */}
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>Available Credits</span>
                  <span>{creditsAvailable} Credits</span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{price} x {bookingWeeks} { bookingWeeks === 1 ? 'week' : 'weeks' }</span>
                  <span>{creditsToSpend} Credits</span>
                </div>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                  <span>Total</span>
                  <span>{creditsToSpend} Credits Used</span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>Credits Remaining</span>
                  <span>{creditsRemaining} Credits</span>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <Label>Special Requests</Label>
                <Textarea className="mt-1.5" value={specialRequest} onChange={(e) => setSpecialRequest(e.target.value)} />
              </div>

              {/* SUBMIT */}
              <div className="py-6">
                <ButtonPrimary disabled={!bookingValid} onClick={handleBooking} loading={bookingLoading}>Submit Request</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BookingWizardPage;
