import React, { FC, ReactNode } from 'react';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import Heading from 'shared/Heading/Heading';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import HeaderFilter from 'containers/PageHome/HeaderFilter';
import StayCard from 'components/StayCard/StayCard';
import { useListings } from 'config/firebase';

const featuredIds = ['6b2cb07d-2de7-4c91-9222-415c75904724', '36d8350f-efb6-49fd-983b-fd97da70a458', 'ac8e592a-4813-4c5b-9111-ee2f2b2081ed'];

export interface FeaturedPageProps {
  className?: string;
}

const FeaturedPage: FC<FeaturedPageProps> = ({ className = "" }) => {
  const { listings } = useListings();
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} readonly={true} />;
  };

  const filteredListings = listings.filter((listing: any) => featuredIds.includes(listing.id));

  const gridClass = '';

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="nc-BackgroundSection xl:rounded-[40px] py-8 bg-neutral-100 dark:bg-black dark:bg-opacity-20">
        <div className="container relative">
          <div className='nc-SectionGridFeaturePlaces relative'>
            <div
              className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
            >
              {(filteredListings as any).map((stay: any) => renderCard(stay))}
            </div>
            {/* <div className='flex mt-16 justify-center items-center'>
              <ButtonPrimary loading>Show me more</ButtonPrimary>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedPage;
