import { atom } from 'recoil';

export const bookingDateRangeState = atom({
  key: 'bookingDateRangeState',
  default: {
    dateRange: {
      startDate: null,
      endDate: null,
    },
  },
});

export const bookingLocationState = atom({
  key: 'bookingLocationState',
  default: '',
});

export const bookingGuestState = atom({
  key: 'bookingGuestState',
  default: {
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  },
});
