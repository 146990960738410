import React from 'react';
// import ButtonPrimary from 'shared/Button/ButtonPrimary';
import CommonLayout from './CommonLayout';
import moment from 'moment';
import { useParticipant, useContributions } from 'config/firebase';

const AccountBilling = () => {
  const { participant } = useParticipant();
  const { contributions } = useContributions();
  const contributionsSorted = contributions.sort((a, b) => a.contributionDate.seconds < b.contributionDate.seconds ? 1 : -1);

  return (
    <div>
      <CommonLayout>
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="col-span-1 space-y-2 md:space-y-0">
            <h5 className="text-sm font-semibold">Credits Available:</h5>
            <h2 className="text-5xl font-semibold">{participant.activeCredits}</h2>
          </div>
          <div className="col-span-1 space-y-2 md:space-y-0">
            <h5 className="text-sm font-semibold">Credits Spent (including pending):</h5>
            <h2 className="text-5xl font-semibold">{participant.spentCredits}</h2>
          </div>
          <div className="col-span-1 space-y-2 md:space-y-0">
            <h5 className="text-sm font-semibold">Total Vested Credits:</h5>
            <h2 className="text-5xl font-semibold">{participant.vestedCredits}</h2>
          </div>
          <div className="col-span-1 space-y-2 md:space-y-0">
            <h5 className="text-sm font-semibold">Price per Credit:</h5>
            <h2 className="text-5xl font-semibold">${participant.creditPrice.toLocaleString('en-US', { minimumFractionDigits: 2 })}</h2>
          </div>
        </div>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Contributions</h2>
          <div className="max-w-2xl">
            <span className="text-neutral-700 dark:text-neutral-300 block">
              Your account is setup to deduct <strong>${participant.employeePaymentPerPayroll.toFixed(2)}</strong> from your next paycheck.
            </span>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block text-sm">
              To make changes to your contributions, please contact your employer or plan sponsor. For more questions regarding Serentio contributions, you can reach out to us at <a className="font-semibold text-primary-700 dark:text-primary-500" href="mailto:support@serentio.com">support@serentio.com</a>
            </span>
            <div className="w-full border-b border-neutral-200 my-4 dark:border-neutral-700"></div>
            <span className="text-xl font-semibold block">History</span>
            <br />
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full">
                      <thead className="border-b dark:border-neutral-700">
                        <tr>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Date
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Employer Contribution
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Employee Contribution
                          </th>
                          <th scope="col" className="text-sm font-medium text-gray-900 dark:text-gray-100 px-6 py-4 text-left">
                            Source
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          contributionsSorted.slice(0, 50).map((contribution, index) => (
                            <tr className="border-b dark:border-neutral-700" key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">{moment(contribution.contributionDate.seconds * 1000).format('l')}</td>
                              <td className="text-sm text-gray-900 dark:text-gray-100 font-light px-6 py-4 whitespace-nowrap">
                                ${contribution.employerContributionAmount.toFixed(2)}
                              </td>
                              <td className="text-sm text-gray-900 dark:text-gray-100 font-light px-6 py-4 whitespace-nowrap">
                                ${contribution.employeeContributionAmount.toFixed(2)}
                              </td>
                              <td className="text-sm text-gray-900 dark:text-gray-100 font-light px-6 py-4 whitespace-nowrap">
                                {contribution.type}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    <div className="my-4 text-neutral-700 dark:text-neutral-300 block text-sm">
                      For more contributions history and reports, please contact us at <a className="font-semibold text-primary-700 dark:text-primary-500" href="mailto:support@serentio.com">support@serentio.com</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
