import Label from 'components/Label/Label';
import { updateUserPassword, auth } from 'config/firebase';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import swal from 'sweetalert';
import CommonLayout from './CommonLayout';

const AccountPass = () => {
  const [loadedUser, error] = useAuthState(auth);
  const [oldPass, setOldPass] = React.useState('');
  const [newPass, setNewPass] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');

  function handleChangePassword() {
    if (loadedUser) {
      if (newPass !== confirmPass) {
        swal('Error', 'New password and confirm password do not match!', 'error');
      } else if (newPass.length < 8) {
        swal('Error', 'Your new password is not long enough!', 'error');
      } else {
        const email = loadedUser.email || '';
        updateUserPassword(email, oldPass, newPass).then((res) => {
          swal('Success', 'Your password has been updated!', 'success');
        }).catch((err) => {
          console.error(err);
        });
      }
    }
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input type="password" value={oldPass} onChange={(e) => setOldPass(e.target.value)} className="mt-1.5" />
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" value={newPass} onChange={(e) => setNewPass(e.target.value)} className="mt-1.5" />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} className="mt-1.5" />
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={handleChangePassword}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
